/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext';
import { Request } from '../../../scripts/Request';
import { WaitObject } from '../../../scripts/Wait';
import InfiniteScroll from "react-infinite-scroll-component";
import ReactGA from 'react-ga';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { UploadService } from '../../../scripts/UploadService';
import { AppServer } from '../../../scripts/Loadbalancer';
import videojs from 'video.js';
import VideoJS from '../../VideoPlayer/VideoJS'
import moment from 'moment/moment';


let current_element;

const tipos = [
    {tipo_id: 1, nome: "Apresentações"},
    {tipo_id: 2, nome: "Documentos"},
    {tipo_id: 3, nome: "Imagens"},
    {tipo_id: 4, nome: "Vídeos"},
]


export default function Lista() {
    const component_name = "admin/material_promocional";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState({ word: "", tipo_id: "" });
    const [arquivo, setArquivo] = useState({ nome: "", tipo_id: 1, date_doc: moment().format('YYYY-MM'), description: "" });
    

    const hiddenFileInput = useRef(null);
    const _uploadedFiles = useRef([]);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const playerRef = useRef(null);

    


     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                WaitObject(user.nome).then(() => {
                    Init();
                });
            }
        }, 200);


        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    },[]);


    useEffect(() => {
        if(loading || pageLoading) {
            window.w_loadObj(current_element? current_element :".main-content")
        } else {
            window.w_unloadAll();
        }
    }, [loading, pageLoading]);
    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadList();
    }
    //#endregion Init


    //#region Loaders
    const LoadList = () => {
        setPageLoading(true);

        Promise.resolve(new Request().Run("api/MaterialPromocional/List/0/0/0", "POST", search, user.token, 0))
        .then(async(data) => {
            current_element = "";
            setPageLoading(false);
            if(data.toString() !== "error") {
                let old_tipo_id = "";
                let _list = data.list
                _list.map((item, i) => {
                    if(item.tipo_id !== old_tipo_id) {
                        old_tipo_id = item.tipo_id;
                        item.tipo = tipos.find(t => t.tipo_id === item.tipo_id).nome;
                    } else {
                        item.tipo = "";
                    }

                    let _dt = item.date_doc.split('-');
                    item.date_doc = _dt[1] + "/" +_dt[0]
                });
                //console.log(_list);
                setList(_list );
            } else {
                window.swal("Alerta", "Houve um erro, tente novamente mais tarde (MPR001)", "error");
            }
        }).catch(() => {
            window.swal("Alerta", "Houve um erro, tente novamente mais tarde (MPR002)", "error");
        });
    }
    //#endregion Loaders



    //#region Handlers
    const _HandleChange = (event) => {
        setSearch(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandleFChange = (event) => {
        setArquivo(prev => prev = {...prev, [event.target.id]: event.target.value});
    }


    const _HandlerSearchChange = (event) => {
        LoadList();
    }


    const _HandleDelete = (item, i) => {
        Promise.resolve(new Request().Run("api/MaterialPromocional/Delete", "POST", { file_id: item.file_id}, user.token))
        .then((data) => {
            /*let _list = [...list];
            _list.splice(i, 1);
            setList(_list);*/

            LoadList();
        })
    }


    const _HandleUpload = () => {
        hiddenFileInput.current.click();
    }


    const _HandleFileChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
           
        };
        reader.readAsDataURL(files[0]);
        Upload(e);
    }


    const _HandleFocus = () => {
        navigator.clipboard.writeText('https://sunundrive.com/Home/'+user?.guid);
        //window.swal("Sucesso", "Link copiado para área de transferência", "success");
        alert("Link copiado para área de transferência");
    }


    const _HandleVideoFocus = (id) => {
        navigator.clipboard.writeText('https://sunundrive.com/Player/'+id+'/'+user?.guid);
        //window.swal("Sucesso", "Link copiado para área de transferência", "success");
        alert("Link copiado para área de transferência");
    }


    const _HandleDownload = async(item, i) => {
        await downloadFile("https://painel.sunundrive.com/storage/material_promocional/" + item.doc, item.doc)
    }



    const downloadFile = async(url, fileName) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function(){
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
    }

    //#endregion Handlers


    //#region Upload
    const Upload = async(event) => {
        let _arquivo = {...arquivo};
        console.log(_arquivo);

        setPageLoading(true);
        let upload = new UploadService();
        let c = 0;

        upload.Data = _arquivo;
        upload.OnEnd = (result) => {
            c++;

            if(c === event.target.files.length) {
                LoadList(); 
                setArquivo(prev => prev = {...prev, nome: "", description: ""});   
                setPageLoading(false);
                upload.Clear();
                upload = null;
            }
        }

        for(let i=0; i<event.target.files.length; i++) {
            console.log(event.target.files[i]);
            upload.Queue(event.target.files[i]);
        }

       
        await upload.PopUpload(AppServer("/api/MaterialPromocional") + "api/MaterialPromocional/Upload", user.token);
    }
    //#endregion Upload


    //#region Player
    const handlePlayerReady = (player) => {
        playerRef.current = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
    };


    const getMime = (file) => {
        let mime = file.split(".");
        console.log();
        return "video/"+ mime[(mime.length-1)].toString().toLowerCase();
    }
    //#endregion Player


    return (
        <div className="main-content MaterialPromocional">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title">Material Promocional</h4>
                                    
                                    {user.nivel >= 0 && (
                                        <div className="row"> 
                                            <div className="col-xl-12"> 
                                                

                                                <div className="align-right mr-10">
                                                    <button type="button" className="btn btn-outline-primary" onClick={_HandlerSearchChange}>
                                                        <i className="mdi mdi-share-outline"></i> &nbsp;Buscar
                                                    </button>
                                                </div>

                

                                                <div className="search-box align-right mr-10">
                                                    <div className="position-relative">
                                                        <select id="tipo_id" className="form-select" value={search.tipo_id} onChange={_HandleChange}>
                                                            <option value="">Todas áreas</option>
                                                            {
                                                                Array.isArray(tipos) && tipos?.length > 0 && tipos.map((item, i) => (
                                                                    <option key={'tipo_'+item.tipo_id} value={item.tipo_id}>{item.nome}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div> 

                                                <div className="search-box align-right mr-10">
                                                    <div className="position-relative">
                                                        <input id="word" typeName="text" value={search.word} className="form-control rounded bg-light border-0" placeholder="" onChange={_HandleChange}/>
                                                        <i className="bx bx-search search-icon"></i>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                       
                                    )}
                                    

                                   
                                </div>



                                                      
                                <div className="card-body pb-2">
                                    <div className='row'>
                                        <div className={user.nivel>0?'col-sm-12':'col-sm-9'}>
                                            <div className="_table-responsive">
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Arquivo</th>
                                                            <th>Data</th>
                                                            <th>Download</th>
                                                            {user.nivel===0 && (
                                                            <th style={{width:'70px'}}></th>
                                                            )}
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                    
                                                    {list.filter(l => {return l.tipo_id === 1 || l.tipo_id === 2}).map((item, i) => (
                                                        <>
                                                            {
                                                                item.tipo !== '' &&  (
                                                                    <tr className="tr_head">
                                                                        <td colSpan={4}><b>{item.tipo}</b></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            
                                                         
                                                            <tr key={'tr' + i} className="pointer">
                                                                <td>{item.nome}</td>
                                                                <td>{item.date_doc}</td>
                                                                <td><a href={'../storage/material_promocional/'+item.doc} target="_blank" rel="noreferrer">Download</a></td>
                                                                {user.nivel===0 && (<td>
                                                                    <i className="fa fa-trash" onClick={(e) => _HandleDelete( item, i)}></i>
                                                                </td>)}
                                                            </tr>
                                                           
                                                        </>
                                                    ))}

                                                    {
                                                        list.filter(l => {return l.tipo_id === 3}).length > 0 && (
                                                            <>
                                                                {list.filter(l => {return l.tipo_id === 3 && l.tipo !== ''}).map((item, i) => (
                                                                    <>
                                                                        {
                                                                            item.tipo !== '' &&  (
                                                                                <tr className="tr_head">
                                                                                    <td colSpan={4}><b>{item.tipo}</b></td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    
                                                                    </>
                                                                ))}

                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <div className="mt_card_list row">
                                                                            {list.filter(l => {return l.tipo_id === 3}).map((item, i) => (
                                                                                <>
                                                                                    <div className="mt_card_item col-sm-4">
                                                                                        <a href={"https://painel.sunundrive.com/storage/material_promocional/"+item.doc} target="_blank">
                                                                                            <img src={"https://painel.sunundrive.com/storage/material_promocional/"+item.doc} alt={item.nome} />
                                                                                        </a>
                                                                                        <textarea className='form-control' onClick={(e) => _HandleFocus(item)} readOnly={true} value={item.description.replaceAll("@url_afiliado", "https://sunundrive.com/Home/"+user?.guid)}>
                                                                                            {item.description.replaceAll("@url_afiliado", "https://sunundrive.com/Home/"+user?.guid)}
                                                                                        </textarea>
                                                                                        { user.nivel == 0 && (
                                                                                        <div className="mt_card_trash pointer"  onClick={(e) => _HandleDelete(item, i)}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </div>
                                                                                        )}

                                                                                        { user.nivel > 0 && (
                                                                                        <div className="mt_card_trash pointer"  onClick={(e) => _HandleDownload(item, i)}>
                                                                                            <i className="fa fa-download"></i>
                                                                                        </div>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }  


                                                    {
                                                        list.filter(l => {return l.tipo_id === 4}).length > 0 && (
                                                            <>
                                                                {list.filter(l => {return l.tipo_id === 4 && l.tipo !== ''}).map((item, i) => (
                                                                    <>
                                                                        {
                                                                            item.tipo !== '' &&  (
                                                                                <tr className="tr_head">
                                                                                    <td colSpan={4}><b>{item.tipo}</b></td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                    
                                                                    </>
                                                                ))}

                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <div className="mt_card_list row">
                                                                            {list.filter(l => {return l.tipo_id === 4}).map((item, i) => (
                                                                                <>
                                                                                    <div className="mt_card_item col-sm-4">
                                                                                        <VideoJS options={{
                                                                                            controls: true,
                                                                                            responsive: true,
                                                                                            fluid: true,
                                                                                            sources: [{
                                                                                            src: 'https://painel.sunundrive.com/storage/material_promocional/' + item.doc,
                                                                                            type: getMime(item.doc)
                                                                                            }]
                                                                                        }} onReady={handlePlayerReady} />
                                                                                        
                                                                                        <textarea className='form-control' onClick={(e) => _HandleVideoFocus(item.file_id)} readOnly={true} value={item.description.replaceAll("@url_afiliado", "https://sunundrive.com/Home/"+user?.guid)}>
                                                                                            {item.description.replaceAll("@url_afiliado", "https://sunundrive.com/Home/"+user?.guid)}
                                                                                        </textarea>
                                                                                        { user.nivel == 0 && (
                                                                                        <div className="mt_card_trash pointer"  onClick={(e) => _HandleDelete(item, i)}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </div>)}

                                                                                        { user.nivel > 0 && (
                                                                                        <div className="mt_card_trash pointer"  onClick={(e) => _HandleDownload(item, i)}>
                                                                                            <i className="fa fa-download"></i>
                                                                                        </div>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    }        

                                                    
                                                    </tbody>
                                                </table>
                                                <br /> <br />                     
                                            </div>
                                        </div>

                                        <div className={'col-sm-3 ' + (user.nivel>0?'hide':'')} >
                                            <div>
                                                <center>
                                                <h4>Upload de Arquivo</h4>
                                                </center>
                                            </div>
                                            <br />   

                                            <div>
                                                <label>Área</label>
                                                <select id="tipo_id" className="form-select" value={arquivo.tipo_id} onChange={_HandleFChange}>
                                                        {
                                                            Array.isArray(tipos) && tipos?.length > 0 && tipos.map((item, i) => (
                                                                <option key={'qtipo_'+item.tipo_id} value={item.tipo_id}>{item.nome}</option>
                                                        ))
                                                        }    
                                                </select>            
                                            </div> 
                                            <br /> 

                                            <div>
                                                <label>Nome do arquivo</label>
                                                <input id="nome" className="form-control" value={arquivo.nome} onChange={_HandleFChange}/>      
                                            </div> 
                                            <br />   

                                            <div>
                                                <label>Descrição</label>
                                                <textarea id="description" className="form-control" value={arquivo.description} onChange={_HandleFChange}> 
                                                    {arquivo.description}
                                                </textarea>     
                                            </div> 
                                            <br />   

                                            <div>
                                            <label>Mês e Ano</label><br></br>
                                                <input type="month" className="form-control" id="date_doc" name="date_doc" value={arquivo.date_doc} onChange={_HandleFChange}/>
                                            </div> 
                                            <br />              

                                            <div>
                                                <input type="file" ref={hiddenFileInput} onChange={_HandleFileChange} style={{display: 'none'}} accept=".*"/>
                                                <button type="button" className={'btn btn-primary ' + (user.nivel>0 || arquivo.nome==='' || arquivo.date_doc === '' || arquivo.tipo_id === ''?'hide':'')} style={{width: '100%'}}  onClick={_HandleUpload}>
                                                    <i className="mdi mdi-star-plus"></i> &nbsp;Enviar Arquivo
                                                </button>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                

                            </div>
                        </div>


                    
                    </div>
                </div>
            </div>
        </div>


        
   
)

}